import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"
import {isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"
import CtaDisclosureNewtab from "@tightrope/ctadisclosurenewtab"
import CtaDisclosure from "@tightrope/ctadisclosure"

const prdbestData = import("./data/data.json");

export default function prdbest() {
  let disclosure;
  if (isChrome()) {
    disclosure = <CtaDisclosureNewtab data={prdbestData}></CtaDisclosureNewtab>;
  }
  else {
    disclosure = <CtaDisclosure data={prdbestData}></CtaDisclosure>;
  }
  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    body {
      background-image: url(/img/bmbg.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position-x: center;
    }

    .footerlinks-module--br,
     .footerlinks-module--br a{
      color: #f1f1f1;
    }

    .trbm_cta {
        padding: 15px 70px;
        border: none;
        font-size: 35px;
        color: #fff;
        font-weight: normal;
        background: #FF7E1A!important;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s ease;
    }

    .trbm-module--trbm h1 {
      font-size: 45px;
    }

    .trbm-module--trbm .trbm-module--logo {
      width: 250px;
    }

    .trbm-module--trbm .trbm-module--bullets {
      list-style: disc;
    }

    .trbm-module--trbm .trbm-module--steps {
      display: none;
    }

    #ctadisclosurenewtab-module--ctadisclaimer {
      padding: 20px 9% 5px;
    }

    .trbm-module--trbm .trbm-module--card {
      margin-top: 80px;
    }
    `}
    </style>
    <title>Free Horoscopes - horoscopeoftheday.com</title></Helmet>
    <section>
      <Trbm data={prdbestData}>{disclosure}</Trbm>
      </section>
    </HomepageLayout>
  )
}
